import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'

class Msos extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Msos'
}
const servicePath = 'msos'
const servicePlugin = makeServicePlugin({
  Model: Msos,
  // IMPORTANT
  whitelist: ['$eager', '$iRegexp', '$regex', '$options', '$mergeEager', '$service', '$query', '$select', '$field'],
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
